import searchService from "@/services/searchService";

const state = {
  query: "",
  titles_length: 0,
  articles_length: 0,
  filters: null,
  subjects: null,
  subjectsRelated: null
};

const getters = {
  query: state => {
    return state.query;
  },
  titles_length: state => state.titles_length,
  articles_length: state => state.articles_length
};

const actions = {
  // user({ commit }, user) {
  //     commit('setUser', user);
  // },
  getHeaderInfo({ commit }) {
    return new Promise((resolve, reject) => {
      searchService
        .getHeaderInfo()
        .then(response => {
          commit("setHeaderInfo", response);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchSubjects({ commit }, payload) {
    commit("setFilters", payload);
    return new Promise((resolve, reject) => {
      searchService
        .searchSubjects(payload)
        .then(response => {
          commit("setSubjects", response.results);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  searchSubjectsRelated({ commit }, payload) {
    return new Promise((resolve, reject) => {
      searchService
        .searchSubjectsRelated(payload)
        .then(response => {
          commit("setSubjectsRelated", response.results);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const mutations = {
  setHeaderInfo(state, payload) {
    state.titles_length = payload["titles_length"];
    state.articles_length = payload["articles_length"];
  },
  setFilters(state, payload) {
    state.filters = payload;
    if (payload) {
      state.query = payload.q;
    }
  },
  setSubjects(state, payload) {
    state.subjects = payload;
  },
  setSubjectsRelated(state, payload) {
    state.subjectsRelated = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
