import store from '../../../../store'
import index, {
    mapState
} from 'vuex';

import SubjectListHeader from "@/components/subject-list-header";
import SubjectListItem from "@/components/subject-list-item";
import AdBanner from "@/components/ad-banner";

export default {
    store,
    name: 'src-components-home',
    components: {
        'subject-list-header': SubjectListHeader,
        'subject-list-item': SubjectListItem,
        'ad-banner': AdBanner
    },
    props: [],
    data() {
        return {
            bannerCount: 0,
        }
    },
    computed: {
        ...mapState({
            lists: state => state.lists.lists,
        }),
    },
    created: function() {
        this.$store.dispatch('lists/listSubjects', { keyname: ['recent', 'recentdvd'] });
    },
    mounted() {},
    methods: {
        currentIndex(lists, index, subIndex){
            let current = 0;
            for(let i=0; i<index; i++){
                if(lists[i].sub_lists){
                    current += lists[i].sub_lists.length
                }
            }
            return current + subIndex + 1;
        }
    },
    title: 'MRQE'
}