
export default {
  name: 'src-components-not-found',
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  },
  title: 'MRQE - 404 Not Found'
}


