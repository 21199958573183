import pagesService from '@/services/pagesService'

const state = {
    static_page: null,
}

const getters = {
    static_page: state => state.static_page,
}

const actions = {
    getStaticPage({ commit }, payload) {
        return new Promise((resolve, reject) => {
            pagesService.getStaticPage(payload)
                .then(response => {
                    if (response) {
                        commit('setStaticPage', response[0]);
                        resolve(response[0]);
                    } else {
                        reject(null);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
}

const mutations = {
    setStaticPage(state, payload) {
        state.static_page = payload;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}