<template>
  <div id="app" class="app">
    <div class="app-background">
      <div class="header-background"></div>
      <div class="container logo-wrapper">
        <span class="logo">Q</span>
      </div>
    </div>
    <div class="app-content">
      <div class="container">
        <div class="row" v-if="!$route.params.noHeader">
          <div class="col">
            <headerbar />
          </div>
        </div>
        <div class="searchbar-wrapper"  v-if="!$route.params.noHeader">
          <searchbar />
        </div>
        <div class="header-nav-wrapper" v-if="!$route.params.noHeader">
          <router-link class="nav-link orkney" to='/'>Home</router-link>
          <span class="seperator">&nbsp;&nbsp;_&nbsp;&nbsp;</span><router-link class="nav-link orkney" to='/lists/incinemas'>In Theatres</router-link>
          <span class="seperator">&nbsp;&nbsp;_&nbsp;&nbsp;</span><router-link class="nav-link orkney" to="/lists/streaming">Streaming</router-link>
          <span class="seperator">&nbsp;&nbsp;_&nbsp;&nbsp;</span><router-link class="nav-link orkney" to="/lists/upcoming">Upcoming</router-link>
          <span class="seperator">&nbsp;&nbsp;_&nbsp;&nbsp;</span><router-link class="nav-link orkney" to="/lists/most_popular">Most Popular</router-link>
          <!-- &nbsp;&nbsp;_&nbsp;&nbsp;<router-link class="nav-link orkney" to='/featured'>List/Features</router-link> -->
        </div>
        <div class="row main-content-wrapper">
          <div class="col">
              <!-- <div class="d-flex flex-column align-items-start" style="flex: 1;"> -->
                <div id="main" class="main" style="flex: 1;">
                  <div v-if="!isMobile()" class="AdvallyTag" id="MRQE_Desktop_LB" data-slot="mrqe.com/mrqe.com_Desktop_LB" data-autosize="superleaderboard2"></div>
                  <div v-else class="AdvallyTag" id="MRQE_Mobile_Leaderboard" data-slot="mrqe.com/mrqe.com_Mobile_Leaderboard" data-autosize="320x100,320x50"></div>
                  <router-view></router-view>
                  <div class="AdvallyTag" id="MRQE_Bottom_Rail" data-slot="mrqe.com/mrqe.com_Bottom_Rail" data-autosize="leaderboard2"></div>
                </div>
              <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="app-footer">
      <footerbar />
      <div class="footer-background"></div>
    </div>
  </div>
</template>
<script>
import Headerbar from "@/pages/layout/header-bar";
import Searchbar from "@/pages/layout/search-bar";
import Footerbar from "@/pages/layout/footer";

import "./assets/css/default.scss";

export default {
  components: {
    headerbar: Headerbar,
    searchbar: Searchbar,
    footerbar: Footerbar
  },
  mounted: function() {},
  computed: {},
  created() {},
  methods: {
    isMobile: function () {
      let check = false;
      (function (a) {
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    }
  },
};
</script>