import Vue from "vue";
import Router from "vue-router";
import store from "@/store";

import Home from "@/pages/home";
import Search from "@/pages/search";
import Lists from "@/pages/lists";
import MovieReviews from "@/pages/movie_reviews";
import StaticPages from "@/pages/static";
import Test from "@/pages/ads";

import Login from "@/pages/auth/login";
import Signup from "@/pages/auth/signup";
import Forgot from "@/pages/auth/forgot";
import NotFound from "@/pages/not-found";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      components: {
        default: Home
      }
    },
    {
      path: "/ads/testing",
      name: "test",
      components: {
        default: Test
      }
    },

    // {
    //     path: '/login',
    //     name: 'login',
    //     components: {
    //         default: Login
    //     },
    // },
    // {
    //     path: '/signup',
    //     name: 'signup',
    //     components: {
    //         default: Signup
    //     }
    // },
    // {
    //     path: '/forgot',
    //     name: 'forgot',
    //     components: {
    //         default: Forgot
    //     }
    // },
    {
      path: "/search",
      name: "search",
      components: {
        default: Search
      }
    },
    {
      path: "/movie_reviews/:slug",
      name: "movie_reviews",
      components: {
        default: MovieReviews
      }
    },
    {
      path: "/lists/:keyname",
      name: "lists",
      components: {
        default: Lists
      }
    },
    {
      path: "/pages/:permalink",
      name: "static_pages",
      components: {
        default: StaticPages
      }
    },
    {
      path: "*",
      name: "not-found",
      components: {
        default: NotFound
      }
    }
  ]
});

router.beforeEach(function(to, from, next) {
  const token = localStorage.getItem("token");
  if (to.name === "login" && token) {
    next({ name: "dashboard" });
  }
  if (to.matched.some(item => item.meta.requiresAuth)) {
    if (!token) {
      next({ name: "login" });
    } else {
      store.dispatch("auth/getUser").then(() => {
        store.dispatch("userView/getUserViews");
        store.dispatch("connection/getConnections");
        store.dispatch("plugin/getPlugins");
      });
    }
  }
  if (to.name === "movie_reviews") {
    store.dispatch("reviews/logPageHit", { slug: to.params.slug });
  }
  next();
});

router.afterEach((to, from)=>{
  setTimeout(()=>{
    window.advally.scanNew();
  }, 1000)
})

export default router;
