import { mapState } from "vuex";

export default {
  name: "src-pages-search",
  components: {},
  props: [],
  data() {
    return {
      loading: false,
      pageOptions: [5, 10, 20, 50],
      totalRows: 0,
      perPage: 10,
      currentPage: 1,
      sortBy: "pretty_title",
      sortDesc: false,
      sortDirection: "asc",
      loading1: false,
      totalRows1: 0,
      perPage1: 20,
      currentPage1: 1,
      sortBy1: "",
      sortDesc1: false,
      sortDirection1: "asc",
      fields: [
        { key: "pretty_title", label: "Title", sortable: true },
        { key: "number_of_reviews", label: "Reviews", sortable: true },
        { key: "sort_popularity", label: "Popularity", sortable: true },
        { key: "year", label: "Year", sortable: true },
      ]
    };
  },
  computed: {
    ...mapState({
      subjects: state => state.search.subjects,
      subjectsRelated: state => state.search.subjectsRelated,
      query: state => state.search.query
    })
  },
  title: `MRQE - Search`,
  watch: {
    $route() {
      this.search();
    }
  },
  mounted() {
    this.search();
  },
  methods: {
    onChange: function() {
      setTimeout(() => this.search(), 0);
    },

    changePerPage: function (param){
      this.searchTitles(1)
    },

    changeSorting: function (res){
      this.sortSearch(res.sortBy)
    },

    searchTitles: function(param) {
      let q = this.$route.query.q;
      let sort = this.sortBy;
      let dir = this.sortDirection;
      let page = param;
      let perPage = this.perPage;
      this.loading = true;
      this.$store
        .dispatch("search/searchSubjects", { q, sort, dir, page, perPage })
        .then(res => {
          this.totalRows = res.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    sortSearch: function(sorting) {
      let q = this.$route.query.q;
      let sort = sorting;
      let dir = this.sortDirection;
      let page = 1;
      let perPage = this.perPage;
      this.loading = true;
      this.$store
        .dispatch("search/searchSubjects", { q, sort, dir, page, perPage })
        .then(res => {
          this.totalRows = res.count;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    searchRelated: function() {
      let q = this.$route.query.q;
      let sort = this.sortBy;
      let dir = this.sortDirection1;
      let page = this.currentPage1;
      let perPage = this.perPage1;
      this.loading1 = true;
      this.$store
        .dispatch("search/searchSubjectsRelated", {
          q,
          sort,
          dir,
          page,
          perPage
        })
        .then(res => {
          this.totalRows1 = res.count;
        })
        .finally(() => {
          this.loading1 = false;
        });
    },

    search: function() {
      this.searchTitles();
      this.searchRelated();
    }
  }
};
