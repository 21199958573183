import Vue from 'vue'
import App from '@/App.vue'

import store from '@/store'
import router from '@/router'

import {BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';


import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min.js'

import VueAnalytics from "vue-analytics";

// import '../static/css/style.css'
import Axios from 'axios'
import titleMixin from "@/mixin/titleMixin";

// using axios and token
Vue.prototype.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

Vue.mixin(titleMixin);

// using bootstrap
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// using vue fontawesome
Vue.component('vue-fontawesome', require('vue-fontawesome-icon/VueFontawesome.vue').default)

// use vue analytics
Vue.use(VueAnalytics,{
    id: 'UA-1244352-1',
    router
})

Vue.config.productionTip = false

// Vue.use(VueRouter)

const vue = new Vue({
    // watch:{'$route': function(to, from) {
    //   window.advally.scanNew();
    // }},
    router,
    store,
    render: h => h(App)
})

vue.$mount('#app')