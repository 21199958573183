import { render, staticRenderFns } from "./src/pages/search.html?vue&type=template&id=3da4fa09&scoped=true&"
import script from "./src/pages/search.js?vue&type=script&lang=js&"
export * from "./src/pages/search.js?vue&type=script&lang=js&"
import style0 from "./src/pages/search.scss?vue&type=style&index=0&id=3da4fa09&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3da4fa09",
  null
  
)

export default component.exports