export default {
    name: 'src-components-forgot',
    components: {},
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        resetPassword() {
            console.log('reset password');
        }
    }
}