import api from "@/services/api";

export default {
  getHeaderInfo() {
    return api.get(`global_metainfo`).then(
      response => response.data,
      error => error.response.data
    );
  },
  searchSubjects(payload) {
    return api.get(`filter_subjects`, { params: payload }).then(
      response => response.data,
      error => error.response.data
    );
  },
  searchSubjectsRelated(payload) {
    return api.get(`filter_subjects_related`, { params: payload }).then(
      response => response.data,
      error => error.response.data
    );
  }
};
