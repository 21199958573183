import {
    mapState
} from 'vuex';

import SubjectListHeader from "@/components/subject-list-header";
import SubjectListItem from "@/components/subject-list-item";

export default {
    name: 'src-pages-lists',
    components: {
        'subject-list-header': SubjectListHeader,
        'subject-list-item': SubjectListItem
    },
    props: [],
    data() {
        return {
            current_list: ''
        }
    },
    computed: {
        ...mapState({
            lists: state => state.lists.lists,
        })
    },
    mounted() {
        this.getPageData(this.$route.params.keyname);
    },
    methods: {
        getPageData: function(keyname) {
            if (keyname) {
                this.$store.dispatch('lists/listSubjects', { keyname });
            }
            if(keyname==='incinemas') {
                this.current_list = 'In Cinemas';
            }
            else if(keyname === 'most_popular'){
                this.current_list = 'Most Popular';
            }else{
                this.current_list = keyname.charAt(0).toUpperCase() + keyname.slice(1)
            }
            document.title = `MRQE - ${this.current_list}`;
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.getPageData(to.params.keyname);
        next();
    },
    title() {
        return `MRQE - ${this.current_list}`
    }
}