import reviewsService from "@/services/reviewsService";

const state = {
  filters: null,
  subject: null,
  articles: null
};

const getters = {
  subject: state => state.subject,
  articles: state => state.articles
};

const actions = {
  // user({ commit }, user) {
  //     commit('setUser', user);
  // },
  getSubject({ commit }, payload) {
    return new Promise((resolve, reject) => {
      reviewsService
        .getSubject(payload)
        .then(response => {
          if (response) {
            commit("setSubject", response[0]);
            resolve(response[0]);
          } else {
            reject(null);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  getArticles({ commit }, payload) {
    commit("setFilters", payload);
    return new Promise((resolve, reject) => {
      reviewsService
        .getArticles(payload)
        .then(response => {
          commit("setArticles", response);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  logPageHit({ commit }, payload) {
    return reviewsService.logPageHit(payload);
  },
  logReviewHit({ commit }, payload) {
    return reviewsService.logReviewHit(payload);
  }
};

const mutations = {
  setFilters(state, payload) {
    state.filters = payload;
  },
  setSubject(state, payload) {
    state.subject = payload;
  },
  setArticles(state, payload) {
    state.articles = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
