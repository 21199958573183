// import authService from '@/services/authService'
import router from '@/router'

export default {
    name: 'src-components-signup',
    components: {},
    props: [],
    data() {
        return {
            username: '',
            email: '',
            password: '',
            first_name: '',
            last_name: '',
            purpose: '',
            err: '',
        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        signUp() {
            const _this = this;
            const user = {
                username: _this.username,
                email: _this.email,
                password: _this.password,
                first_name: _this.first_name,
                last_name: _this.last_name,
                // purpose: this.purpose,
            }

            _this.$store.dispatch('auth/signup', user).then(() => {
                    router.push({ name: 'onboard' });
                })
                .catch(error => {
                    _this.err = error;
                })

            // authService.signup(user).then(function(response) {
            //     console.log(response);
            //     if (response) {
            //         router.push({ name: 'login' });
            //     }
            // });
        }
    }
}