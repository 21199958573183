export default {
    name: 'src-components-subject-list-header',
    components: {},
    props: ['main_header', 'sub_header', 'show_full'],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}