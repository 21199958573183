import store from '../../../../../store'
import {
    mapState
} from 'vuex';

export default {
    store,
    name: 'src-components-header-bar',
    computed: {
        ...mapState({
            titles_length: state => state.search.titles_length,
            articles_length: state => state.search.articles_length,
        })
    },
    components: {},
    props: [],
    data() {
        return {}
    },
    created: function() {
        this.$store.dispatch('search/getHeaderInfo', null);
    },
    mounted() {

    },
    methods: {

    }
}