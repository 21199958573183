import authService from '@/services/authService'
import axios from 'axios'
import { isArray } from 'util'

const state = {
    user: null,
    status: '',
    token: localStorage.getItem('token') || '',
}

const getters = {
    user: state => {
        return state.user
    }
}

const actions = {
    // user({ commit }, user) {
    //     commit('setUser', user);
    // },
    getUser({ commit }) {
        return new Promise((resolve, reject) => {
            // axios({ url: 'api/auth', data: user, method: 'POST' })
            const token = localStorage.getItem('token')
            authService.getUser({ token })
                .then(response => {
                    if (response.user) {
                        const user = response.user;
                        axios.defaults.headers.common['Authorization'] = token
                        commit('setUser', user);
                        resolve(user);
                    } else {
                        const err = response[Object.keys(response)[0]];
                        localStorage.removeItem('token');
                        reject(isArray(err) ? err.join('<br>') : err);
                    }
                })
                .catch(err => {
                    localStorage.removeItem('token');
                    reject(err);
                });
        });
    },
    updateProfile({ commit }, updateData) {
        return new Promise((resolve, reject) => {
            // axios({ url: 'api/auth', data: user, method: 'POST' })
            authService.updateProfile({ token: localStorage.getItem('token'), updateData })
                .then(() => {
                    commit('setUser', updateData);
                    resolve();
                })
                .catch(err => {
                    localStorage.removeItem('token');
                    reject(err);
                });
        });
    },
    login({ commit }, user) {
        return new Promise((resolve, reject) => {
            // axios({ url: 'api/auth', data: user, method: 'POST' })
            authService.login(user)
                .then(response => {
                    if (response.token) {
                        const token = response.token;
                        localStorage.setItem('token', token)
                        axios.defaults.headers.common['Authorization'] = token
                        commit('setUser', user, token);
                        resolve(user);
                    } else {
                        const err = response[Object.keys(response)[0]];
                        reject(isArray(err) ? err.join('<br>') : err);
                    }
                })
                .catch(err => {
                    localStorage.removeItem('token')
                    reject(err)
                });
        });
    },
    logout({ commit }) {
        return new Promise((resolve) => {
            authService.logout()
                .then(() => {
                    localStorage.removeItem('token')
                    delete axios.defaults.headers.common['Authorization']
                    commit('setUser', null, "");
                    resolve();
                })
        })
    },
    signup({ commit }, user) {
        return new Promise((resolve, reject) => {
            // axios({ url: 'api/auth', data: user, method: 'POST' })
            authService.signup(user)
                .then(response => {
                    if (response.token) {
                        const token = response.token;
                        localStorage.setItem('token', token)
                        axios.defaults.headers.common['Authorization'] = token
                        commit('setUser', user, token);
                        resolve(user);
                    } else {
                        const err = response[Object.keys(response)[0]];
                        reject(isArray(err) ? err.join('<br>') : err);
                    }
                })
                .catch(err => {
                    localStorage.removeItem('token')
                    reject(err)
                });
        });
    },
    resetPassword(user) {
        authService.resetPassword(user).then(() => {
            // commit('deleteMessage', msgId)
        })
    }
}

const mutations = {
    setUser(state, user, token) {
        state.user = user;
        if (token) {
            state.token = token;
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}