import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import search from './modules/search'
import lists from './modules/lists'
import reviews from './modules/reviews'
import pages from './modules/pages'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        auth,
        search,
        lists,
        reviews,
        pages
    }
})