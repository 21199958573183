import { mapState } from "vuex";

export default {
  name: "src-pages-movie-reviews",
  components: {},
  props: [],
  data() {
    return {
      orderBy: "sort_classic",
      orderDir: "asc",
      subject_id: null,
      bold: 'sort_classic',
      director: '',
      title: '',
      year: '',
    };
  },
  computed: {
    ...mapState({
      subject: state => state.reviews.subject,
      articles: state => state.reviews.articles,
    }),
    histograms() {
      const columns = ["A", "B", "C", "D", "F"];
      const buckets = {};
      if (this.subject.metric && this.subject.histograms) {
        let total = 0;
        for (let i = 1; i <= 5; i++) {
          const bucket = this.subject.histograms[`bucket${i}`];
          total += bucket;
          buckets[columns[i - 1]] = bucket;
        }
        if (total) {
          columns.forEach(c => (buckets[c] = buckets[c] / total));
        }
      }
      return buckets;
    },
    movie_datas() {
      let attrs = [];
      this.storeMovieData();
      if (this.subject && this.subject.movie_datas) {
        attrs = this.subject.movie_datas.map(d => {
          const attribute_name = d.attribute_name.replace("_", " ");
          const value = d.value.split(", ");
          if(attribute_name === 'director'){
            this.director = value;
          }
          return { attribute_name, value };
        });
      }
      return attrs;
    }
  },
  mounted() {
    this.getMovieReviews();
  },
  updated() {
    JustWatch.reloadWidgets();
  },
  title (){
    return `MRQE - ${this.current_list}`
  },
  methods: {
    getMovieReviews: function() {
      let slug = this.$route.params.slug;
      this.$store.dispatch("reviews/getSubject", { slug }).then(res => {
        this.subject_id = res.id;
        // this.$store.dispatch('reviews/getArticles', { subject_id: res.id }).then(result => {})
        this.getArticles();
      });
    },
    getArticles: function() {
      let ordering = (this.orderDir === "desc" ? "-" : "") + this.orderBy;
      this.$store
        .dispatch("reviews/getArticles", {
          subject_id: this.subject_id,
          ordering: ordering
        })
        .then(result => {});
    },
    navToSearch(q) {
      this.$router.push("/search?q=" + q);
    },
    sortBy(o) {
      let sorter = "";
      let dir = "asc";
      if (o === "classical") sorter = "sort_classic";
      else if (o === "source") sorter = "source";
      else if (o === "rating") sorter = "sort_rating";
      else if (o === "popularity") sorter = "sort_popularity";

      if (sorter === this.orderBy) {
        dir = this.orderDir === "desc" ? "asc" : "desc";
      }
      this.bold = sorter.replace('-','')
      this.orderBy = sorter;
      this.orderDir = dir;
      this.getArticles();
    },

    openArticle(article){
      window.open(article.url, '_blank')
    },

    gotoArticle(article) {
      this.$store
        .dispatch("reviews/logReviewHit", { article_id: article.id })
        .then(() => console.log('log review hitted'));
    },

    storeMovieData(){
      let words = this.subject.pretty_title.split(' ');
      this.year = words[words.length -1 ].replace(/^\D+|\D+$/g, "");
      words.length = words.length -1;
      this.title = words.join(' ');
      document.title = `MRQE - ${this.title}`
    }
  }
};
