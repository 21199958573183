import api from '@/services/api'

export default {
    getUser(payload) {
        return api.post(`auth/get_user/`, payload)
            .then(response => response.data,
                error => error.response.data)
    },
    updateProfile(payload) {
        return api.post(`auth/update_user/`, payload)
            .then(response => response.data,
                error => error.response.data)
    },
    login(payload) {
        return api.post(`auth/`, payload)
            .then(response => response.data,
                error => error.response.data)
    },
    logout() {
        return api.post(`auth/`, null)
            .then(response => response.data,
                error => error.response.data)
    },
    signup(payload) {
        return api.post(`auth/register/`, payload)
            .then(response => {
                return api.post(`auth/`, response.data);
            })
            .then(response => response.data)
            .catch(error => error.response.data)
    },
    resetPassword(payload) {
        return api.post(`auth/password_reset`, payload)
            .then(response => response.data,
                error => error.response.data)
    },
}