import store from "../../../../../../store";
import { mapState } from "vuex";

export default {
  store,
  name: "src-pages-layout-search-bar",
  computed: {
    ...mapState({
      query: state => state.search.query
    })
  },
  components: {},
  props: [],
  created: function() {
    this.$store.dispatch("search/getHeaderInfo", null);
  },
  mounted: function() {
    let q = this.$route.query.q;
    if (q) {
      this.$router.push("/search?q=" + q);
    }
  },
  data() {
    return {
      search_string: this.query
    };
  },
  watch: {
    query() {
      this.search_string = this.query;
    }
  },
  methods: {
    onSearchChange: function(e) {
      if (e.key === "Enter") {
        this.onSearch();
      }
    },

    onSearch: function() {
      // if (this.$route === '/search')
      // console.log(this.$route);
      this.$router.push("/search?q=" + this.search_string);
      // window.location.href = '/search?q=' + (this.search_string || '');
    }
  }
};
