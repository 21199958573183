import {
    mapState
} from 'vuex';

export default {
    name: 'src-pages-static',
    components: {},
    props: [],
    data() {
        return {

        }
    },
    computed: {
        ...mapState({
            page: state => state.pages.static_page
        })
    },
    mounted() {
        this.getPageData(this.$route.params.permalink);
    },
    methods: {
        getPageData: function(permalink) {
            if (permalink) {
                this.$store.dispatch('pages/getStaticPage', { permalink });
            }
        }
    },
    beforeRouteUpdate(to, from, next) {
        this.getPageData(to.params.permalink);
        next();
    }
}