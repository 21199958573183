// import authService from '@/services/authService'
// import { isArray } from 'util';
import router from '@/router';

export default {
    name: 'src-components-login',
    components: {},
    props: [],
    data() {
        return {
            username: '',
            password: '',
            err: '',
        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {
        login: function() {
            const _this = this;
            const user = { username: _this.username, password: _this.password };
            _this.$store.dispatch('auth/login', user)
                .then(() => {
                    router.push({ name: 'dashboard' });
                })
                .catch(err => {
                    _this.err = err;
                });
        }
    }
}