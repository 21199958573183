export default {
    name: 'src-components-ad-banner',
    components: {},
    props: ['list_index'],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {
    },
    methods: {

    }
}