import listService from '@/services/listService'

const state = {
    keyname: null,
    lists: null
}

const getters = {
    lists: state => state.lists,
}

const actions = {
    // user({ commit }, user) {
    //     commit('setUser', user);
    // },
    listSubjects({ commit }, payload) {
        commit('setKeyname', payload)
        return new Promise((resolve, reject) => {
            listService.listSubjects(payload)
                .then(response => {
                    commit('setLists', response);
                    resolve(response);
                })
                .catch(err => {
                    reject(err);
                });
        });
    },
}

const mutations = {
    setKeyname(state, payload) {
        state.keyname = payload;
    },
    setLists(state, payload) {
        state.lists = payload;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}