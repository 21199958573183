export default {
    name: 'src-components-subject-list-item',
    components: {},
    props: ['subject', 'mini'],
    data() {
        return {

        }
    },
    computed: {
        histograms() {
            const columns = ['A', 'B', 'C', 'D', 'F'];
            const buckets = {};
            if (this.subject.metric && this.subject.histograms) {
                let total = 0;
                for (let i = 1; i <= 5; i++) {
                    const bucket = this.subject.histograms[`bucket${i}`];
                    total += bucket;
                    buckets[columns[i - 1]] = bucket;
                }
                if (total) {
                    columns.forEach(c => buckets[c] = buckets[c] / total);
                }
            }
            return buckets;
        },
        movie_datas() {
            let attrs = [];
            if (this.subject && this.subject.movie_datas) {
                attrs = this.subject.movie_datas.map(d => {
                    const attribute_name = d.attribute_name.replace('_', ' ');
                    const value = d.value.split(', ');
                    return { attribute_name, value };
                });
            }
            return attrs;
        }
    },
    mounted() {},
    methods: {
        navToSearch(q) {
            this.$router.push('/search?q=' + q);
        }
    }
}