import api from "@/services/api";

export default {
  getSubject(payload) {
    return api.get(`subjects`, { params: payload }).then(
      response => response.data,
      error => error.response.data
    );
  },
  getArticles(payload) {
    return api.get(`articles`, { params: payload }).then(
      response => response.data,
      error => error.response.data
    );
  },
  logPageHit(payload) {
    return api.post(`page_hits`, payload).then(
      response => response.data,
      error => error.response.data
    );
  },
  logReviewHit(payload) {
    return api.post(`review_hits`, payload).then(
      response => response.data,
      error => error.response.data
    );
  }
};
