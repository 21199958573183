import store from '../../../../store'
import {
    mapState
} from 'vuex';

import SubjectListHeader from "@/components/subject-list-header";
import SubjectListItem from "@/components/subject-list-item";

export default {
    store,
    name: 'src-components-test',
    components: {
        'subject-list-header': SubjectListHeader,
        'subject-list-item': SubjectListItem
    },
    props: [],
    data() {
        return {
        }
    },
    computed: {
        ...mapState({
            lists: state => state.lists.lists,
        })
    },
    created: function() {
        this.$store.dispatch('lists/listSubjects', { keyname: ['recent', 'recentdvd'] });
    },
    mounted() {
        let adScript = document.createElement('script');
        adScript.setAttribute('src', '//cdn.intergient.com/pageos/pageos.js');
        adScript.setAttribute('id', 'tyche');
        adScript.setAttribute('async', true);
        adScript.setAttribute('type', 'text/javascript');
        document.head.appendChild(adScript);

        let secScript = document.createElement('script');
        secScript.setAttribute('type', 'text/javascript');
        secScript.text = 'var tyche = {\n' +
            'mode: \'tyche\',\n' +
            'config: \'https://config.playwire.com/1024337/v2/websites/72803/banner.json\',\n' +
            'passiveMode: true\n' +
            '}\n' +
            '// 2. Configure ad units\n' +
            'var pwUnits = [\n' +
            '{\n' +
            ' type: \'trendi_video\'\n' +
            '},\n' +
            '{\n' +
            ' type: \'bottom_rail\'\n' +
            '},\n' +
            '{\n' +
            ' selectorId: \'top-leaderboard\',\n' +
            ' type: \'leaderboard_atf\'\n' +
            '},\n' +
            '{\n' +
            ' selectorId: \'bottom-leaderboard\',\n' +
            ' type: \'leaderboard_btf\'\n' +
            '}\n' +
            ']\n' +
            '// function that is called when tyche is ready\n' +
            'var init = function () {\n' +
            '    tyche\n' +
            '    .addUnits(pwUnits)\n' +
            '    .then(() => {\n' +
            '        tyche.displayUnits();\n' +
            '    }).catch( (e) =>{\n' +
            '    // catch errors\n' +
            '    tyche.displayUnits();\n' +
            '    console.log(e);\n' +
            '});\n' +
            '}\n' +
            '// 3.\n' +
            'tyche.onReady = function () { // attach function to tyche onReady\n' +
            '  init();\n' +
            '}'

        document.head.appendChild(secScript)

    },
    methods: {
    },
}